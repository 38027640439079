import React from "react";
import  DashboardWrapper  from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Storynavs from "../storynavs";
import StoriesTable from "./StoriesTable";


export default function Stories(){

  const navigate = useNavigate();

  function toDashboard(e) {
    e.preventDefault()
    navigate("/dashboard");
  }


  return( 
    <DashboardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
           
            <div className="header mt-md-2">
 
              <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                        
                      </li>
                      <li className="breadcrumb-item active">Publications</li>
                    </ol>
              </nav>

              <div className="header-body">

      
                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Stories
                </h1>

              </div>
            </div>


            <div className="content">
              <Storynavs></Storynavs>

              <StoriesTable />

            </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
  )
}