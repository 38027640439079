
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { fetchStoryDetailsApi } from '../../../../api/publisher';
import DashboardWrapper from '../../../../components/DashboardWrapper'
import { fetchGenres } from '../../../../redux-store/Actions/genre-action';
import {updateStoryApi} from "../../../../api/publisher"
import {toast} from "react-toastify"


// import { updateIssuesApi } from "../../../../api/publisher";




function EditStory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params   = useParams();
  const [data,setData] = useState({})
  const [story,setStory] = useState();
  const [isLoading,setIsLoading] = useState(false)
  const { genres } = useSelector(
    (state) => state.genres
  );
  
  
  const handleSubmit=(e)=>{
    e.preventDefault();
    var d = data;

    d.id = story.id;
    console.log("data: ",d);
    if(data.id){
      setIsLoading(true);
      updateStoryApi(d).then(result=>{
        console.log("result",result.data);
        setStory(result.data.data);
        toast.success(result.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/publications/stories")
      }).catch(e=>{
        console.log(e)
      })
      .finally(()=>{
        setIsLoading(false);
      })
    }
    else{
      alert("Invalid data")
    }
  }
  const handleChange=(e)=>{
    let id = e.target.id;
    let val = e.target.value.trim();
    if(id == "releaseDate"){
      val = (new Date(Date.parse(val))).toISOString().replaceAll("T"," ").split(".")[0];
    }
    let d = data;
    d[id] = val;
    setData(d)
  }

  const id = params.id 
  const countries = localStorage.getItem("RIFALYCOUNTRIES") ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES")):[];
  useEffect(() => {
    setIsLoading(true);
    fetchStoryDetailsApi(id).then(res=>{
      setStory(res.data.data);
     
    }).catch(e=>{
      alert("Hello")
    }).finally(()=>{
      setIsLoading(false);
      
    })
    dispatch(fetchGenres());
  },[dispatch]);

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="header mt-md-3 mb-5">
            <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <a href="/dashboard" onClick={(e)=>{e.preventDefault();navigate("/dashboard")}} >Dashboard</a>
                              
                            </li>
                            <li className="breadcrumb-item" aria-current="page">Publications</li>
                            <li className="breadcrumb-item" onClick={(e)=>{e.preventDefault();navigate("/publications/stories")}}>Stories</li>
                            <li className="breadcrumb-item active" aria-current="page"> Edit </li>
                          </ol>
                    </nav>
            </div>

            <div className="row">
              <div className="card">
                { isLoading ? (
                  <div className="card-body">
                    <div className='loader-holder d-flex justify-content-center align-items-center'>
                      <Loader 
                      type="BallTriangle"
                      heigth="100"
                      width="100"
                      color="green"
                      ariaLabel="loading-indicator"
                    />
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    { story && (
                      <div>
                        <div className="p-4">
                          <div>
                            <h6 className="header-pretitle">
                              Overview
                            </h6>

                            <h1 className="header-title">
                              Title: { story.title}
                            </h1>

                          </div>

                          <hr />
                        </div>
                        <form onSubmit={handleSubmit}>
                        <div className="detail-contents pb-3">
                              <div className="row">
                               
                                <div className="col-md-4">
                                  <div className="dc-title">
                                    Title
                                  </div>
                                  <div className="dc-body">
                                    <input type="text" className="form-control" id="title" defaultValue={story.title} onChange={handleChange}/>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">
                                    Genre
                                  </div>
                                  <div className="dc-body">
                                  <select className="form-control" id="genreId" defaultValue={story.genreId} onChange={handleChange}>
                                    {genres.map(g=>{
                                      return <option key={g.id} value={g.id}>{g.name}</option>
                                    })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">
                                  Published Date and Time
                                  </div>
                                  <div className="dc-body">
                                  <input type="text" className="form-control" id="releaseDate" onFocus={(e)=>{e.target.type="date";}} defaultValue={story.releaseDate} onChange={handleChange}/>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">
                                  Price
                                  </div>
                                  <div className="dc-body">
                                  <input type="number" className="form-control" id="price" defaultValue={story.price} onChange={handleChange}/>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="dc-title">
                                  Currency
                                  </div>
                                  <div className="dc-body">
                                  <select className="form-control" id="currency" defaultValue={story.currency} onChange={handleChange}>
                                    {countries.map(c=>{
                                      return <option key={c.countryCode} value={c.currency}>{c.currency}</option>
                                    })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="dc-title">
                                  Language
                                  </div>
                                  <div className="dc-body">
                                  <select className="form-control" id="language" defaultValue={story.language} onChange={handleChange}>
                                    <option value="EN">English</option>
                                    <option value="SW">Swahili</option>
                                    </select>
                                  </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="dc-title">
                                  Descriptrion
                                  </div>
                                  <div className="dc-body">
                                  <textarea className="form-control" id="description" defaultValue={story.description}>
                                  </textarea>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  
                                  <div className="dc-body">
                                    {isLoading ?
                                  <Loader type="TailSpin" color="#ffffff" height={20} width={20} />:
                                  <input type="submit" className='btn btn-primary my-4' value={"Submit"}/>}
                                  </div>
                                </div>
                        </div>
                        </form>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>


          </div>
        </div>
      </div>
    </DashboardWrapper>
  )

}

export default EditStory;
