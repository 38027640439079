import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HomeWrapper from "../../components/HomeWrapper";
import Loader from "react-loader-spinner";
import { loginApi } from "../../api/auth";
import { toast, Slide } from "react-toastify";
import { fetchExchangeRatesApi } from "../../api/reports";
import { fetchPublisherByIdApi } from "../../api/company";
import { fetchCountriesApi } from "../../api/publisher";
import { ReactComponent as RifalyLogo } from "../../assets/rifalyPodcasterlogob.svg";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Please Enter a valid email address")
      .required()
      .max(255)
      .label("Email Address or Phone"),
    password: yup.string().required(),
  })
  .required();

export default function Login() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await loginApi(data);
      if (response.status == 200) {
        var user = response.data.data;
        localStorage.setItem(
          "RIFALYPODCASTER",
          JSON.stringify(response.data.data)
        );
        try {
          const [pubResult, fxResult, cRes] = await Promise.all([
            fetchPublisherByIdApi(user.companyId),
            fetchExchangeRatesApi("TZS"),
            fetchCountriesApi(),
          ]);
          if (pubResult) {
            console.log("xc: ", pubResult.data);
            localStorage.setItem(
              "RIFALYCOMPANY",
              JSON.stringify(pubResult.data.data)
            );

            if (fxResult) {
              localStorage.setItem(
                "RIFALYCURRENCYRATES",
                JSON.stringify(fxResult.data)
              );
            } else
              localStorage.setItem("RIFALYCURRENCYRATES", JSON.stringify([]));
          }
          if (cRes) {
            localStorage.setItem(
              "RIFALYCOUNTRIES",
              JSON.stringify(cRes.data.data)
            );
          }
        } catch (e) {
          console.log("🚀 ~ file: Login.js:78 ~ onSubmit ~ e:", e);
        }

        setLoading(false);
        navigate("/dashboard", { state: { firstTime: true } });
      } else {
        setLoading(false);
        toast.error("Login failed, please check your email and password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Login failed, please check your email and password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <HomeWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-10 m-center">
            <div style={{ padding: "20px 0px" }}>
              <RifalyLogo />
            </div>
            <h1 className="text-black text-bold mb-3">Login</h1>
            <p className="text-black mb-5">
              <b>
                Welcome back to{" "}
                <span style={{ color: "#00b17a" }} className="text-bold mb-5">Rifaly</span>
              </b>
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-center">
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                  autoFocus
                  placeholder="Enter your email"
                  {...register("email")}
                />

                <p className="errors">{errors.email?.message}</p>
              </div>

              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="*********"
                  {...register("password")}
                />
              </div>

              <div className="form-group row">
                <div className="col-md-12">
                  {loading ? (
                    <span className="btn btn-primary">
                      <Loader
                        type="TailSpin"
                        color="#ffffff"
                        height={20}
                        width={20}
                      />
                    </span>
                  ) : (
                    <button className="btn btn-primary w-100" type="submit">
                      Log In
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <p className="text-center text-muted mt-5">
        Forgot Password?
        <span className="text-primary pl-3 mx-2">
          <NavLink to="/forgot-password">click here to reset</NavLink>
        </span>
      </p>
    </HomeWrapper>
  );
}
