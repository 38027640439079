import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { STORIESCOLUMNS } from "./StoryColumns";
import { fetchStories } from "../../../../redux-store/Actions/story-action";
import EmptyData from "../../../../components/EmptyData/Index";
import StoryFilter from "./StoryFilter";
import { AnimatePresence, motion } from "framer-motion";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function StoriesTable() {
  const user = JSON.parse(localStorage.getItem("RIFALYPODCASTER"));
  // const canUpload = parseInt(user.role) === 2 ||  parseInt(user.role) === 6;
  const columns = useMemo(() => STORIESCOLUMNS, []);
  const dispatch = useDispatch();

  const { stories } = useSelector((state) => state.stories);

  const data = stories;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly Uploaded stories ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  useEffect(() => {
    let pid = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).channel.id;
    dispatch(fetchStories(pid));
  }, [dispatch]);

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <StoryFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>
          <div className="col-auto">
            <Link
              to="/publications/stories/create"
              className="btn btn-primary lift"
            >
              <span>
                <i className="fe fe-plus"></i>
              </span>
              Create Story
            </Link>
          </div>
      </div>

      <div className="row mt-2 body-content">
        {stories && stories.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Registered Stories</h4>

                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <motion.tr
                              {...row.getRowProps({
                                layoutTransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </motion.tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? stories.length
                      : pageIndex * pageSize + pageSize > stories.length
                      ? stories.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {stories.length} elements
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">Page {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Stories" path="/publications/stories/create" />
        )}
      </div>
    </div>
  );
}

export default StoriesTable;
