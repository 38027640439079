import axios from "axios";
import api_url from "./api_config";

const baseURL = api_url.PUBLISHER_URL;
const countryApi = axios.create({
  baseURL: api_url.COUNTRIES_URL,
  headers: {"Content-type": "application/json"},
});
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});


// fetch genres
export const fetchGenresApi = () => {
  return api({
    url: `genres/list`,
    method: "GET",
  });
};

/* 
  stories API
*/
export const fetchStoriesApi = (channelId) => {
  return api({
    url: `stories/all/${channelId}`,
    method: "GET",
  });
};

export const fetchStoryDetailsApi = (id) => {
  return api({
    url: `audio/v3/show/${id}`,
    method: "GET",
  });
};

export const createStoriesApi = (data) => {
  return api({
    url: `audio/v3`,
    data: data,
    method: "POST",
  });
};
export const createStoriesViaRSSApi = (data) => {
  return api({
    url: `audio/v3/rss-feed`,
    data: data,
    method: "POST",
  });
};
export const createStoriesSeasonsApi = (data) => {
  return api({
    url: `audio/v3`,
    data: data,
    method: "POST",
  });
};
export const fetchSeasonsDetailsApi = (id) => {
  return api({
    url: `audio/v3/collections/${id}`,
    method: "GET",
  });
};

export const deleteStoriesApi = (id) => {
  return api({
    url: `audio/v3/`,
    data: id,
    method: "DELETE",
  });
};

export const updateStoryStatusApi = (id, data) => {
  return api({
    url: `audio/v3/${id}`,
    data: data,
    method: "PATCH",
  });
};

export const updateStoryApi = (data) => {
  return api({
    url: `audio/v3/update`,
    data: data,
    method: "POST",
  });
};
export const formatDuration = (seconds) => {
  let hours = Number(seconds / 3600).toFixed(0);
  let minutes = Number((seconds % 3600) / 60).toFixed(0);
  let secs = Number(seconds - minutes * 60 - hours * 3600).toFixed(0);
  return `${hours}:${minutes}:${secs}`;
};

//FETCH COUNTRIES
export const fetchCountriesApi = () => {
  return countryApi({url: `supported`, method: "GET"});
};
