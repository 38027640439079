import { fetchStoriesApi, fetchStoryDetailsApi } from "../../api/publisher";

export const FETCH_STORIES_LOADING = "FETCH_STORIES_LOADING";
export const FETCH_STORIES_SUCCESS = "FETCH_STORIES_SUCCESS";
export const FETCH_STORY_DETAILS_LOADING = "FETCH_STORY_DETAILS_LOADING";
export const FETCH_STORY_DETAILS_SUCCESS = "FETCH_STORY_DETAILS_SUCCESS";



export const fetchStories = (id) => {
  return (dispatch) => {
    dispatch(fetchStoriesLoading());
    return fetchStoriesApi(id)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchStoriesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const fetchStoryDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchStoryDetailsLoading());
    return fetchStoryDetailsApi(id)
      .then((res) => {
        console.log(res.data)
        const data = (res.data.data) || {};
        dispatch(fetchStoryDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


//  staff 

const fetchStoriesLoading = () => {
  return {
    type: FETCH_STORIES_LOADING,
  };
};


const fetchStoriesSuccess = (data) => {
  return {
    type: FETCH_STORIES_SUCCESS,
    payload: data,
  };
};


//  story details

const fetchStoryDetailsLoading = () => {
  return {
    type: FETCH_STORY_DETAILS_LOADING,
  };
};

const fetchStoryDetailsSuccess = (data) => {
  return {
    type: FETCH_STORY_DETAILS_SUCCESS,
    payload: data,
  };
};
