import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { REPORTCOLUMNS } from "./ReportColumns";
import EmptyData from "../../../../components/EmptyData/Index";
import ReportFilter from "./ReportFilter";
import { thousandSeparator } from "../common";
import * as FileSaver from "file-saver";
import { fetchRevenueApi } from "../../../../api/reports"
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import api_config from "../../../../api/api_config";
import { DateTime } from "luxon";

function StreamsReportTable() {
  const columns = useMemo(() => REPORTCOLUMNS, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(DateTime.fromMillis(Date.now()).startOf('day').toISO());
  const [endDate, setEndDate] = useState(DateTime.fromMillis(Date.now()).toISO());
  // const [ period, setPeriod ] = useState({end: DateTime.fromMillis(Date.now()).toISO({includeOffset: false}), start: DateTime.fromMillis(Date.now()).startOf('day').toISO({includeOffset: false})});
  const [ start, setStart ] = useState((new Date()).toISOString());
  const [ end, setEnd ] = useState((new Date()).toISOString());
  let pid = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).companyId;
  const user = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).token;
  var fx = localStorage.getItem("RIFALYCURRENCYRATES")
    ? JSON.parse(localStorage.getItem("RIFALYCURRENCYRATES"))
    : { base: "TZS", rates: null };
  var company = localStorage.getItem("RIFALYCOMPANY")
    ? JSON.parse(localStorage.getItem("RIFALYCOMPANY"))
    : null;
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    var value = data.map((d) => {
      let r = d;
      delete r.issueId;
      delete r.price;
      delete r.country;
      delete r.id;
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(value);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly-Report ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const loadData = (id) => {
    setLoading(true);
    let d = { endDate: endDate, startDate: startDate };
    d.token = user.token;
    fetchRevenueApi(id, d)
      .then((result) => {
        let streams = result.data.data;
        setData(streams.stories);
      })
      .catch((e) => {
        console.log(e);
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData(pid);
  }, []);


  const handleDateChange = (e) => {
    if (e.target.id === 'start') setStart((new Date(e.target.value.trim())).toISOString());
    else setEnd((new Date(e.target.value.trim())).toISOString());
  };

  async function handleSearch(startDate, endDate) {
    setLoading(true); 
  
    const annual = {
      start: DateTime.utc(new Date().getFullYear()).startOf('year').toISO({ includeOffset: false }),
      end: DateTime.utc(new Date().getFullYear()).endOf('year').toISO({ includeOffset: false })
    };
  
    let id = JSON.parse(localStorage.getItem('RIFALYPODCASTER')).companyId;
    let d = { endDate: end, startDate: start };
    d.token = user.token;
  
    try {
      const result = await fetchRevenueApi(id, d);
      let streams = result.data.data;
      setData(streams.stories);
    } catch (e) {
      console.log(e);
      setData([]);
    } finally {
      setLoading(false); 
    }
  }
 

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <ReportFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>

        <div className="col-md-3">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              type="date"
              defaultValue={start}
              onChange={handleDateChange}
              id="start"
              className="form-control"
              placeholder="Start date"
            />
            <div className="input-group-text" id="inputStartDate">
              <span className="fe fe-clock"></span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              type="date"
              defaultValue={end}
              onChange={handleDateChange}
              id="end"
              className="form-control"
              placeholder="End date"
            />
            <div className="input-group-text" id="inputEndDate">
              <span className="fe fe-clock"></span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          {loading ? (
            <button className="btn btn-primary">
              <Loader type="TailSpin" color="#ffffff" height={20} width={20} />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={handleSearch}>
              Search
            </button>
          )}
        </div>
      </div>

      <div className="row mt-2 body-content">
        {loading ? (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>Loading data...</p>
          </div>
        ) : data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
              <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                      Streams Reports:
                      {/* TZS{" "}
                      {data.length > 0
                        ? thousandSeparator(
                            data
                              .reduce((t, a) => {
                                t += isNaN(a.revenue) ? 0 : a.revenue;
                                return t;
                              }, 0)
                              .toFixed(2)
                          )
                        : 0} */}
                    </h4>

                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? data.length
                      : pageIndex * pageSize + pageSize > data.length
                      ? data.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {data.length} elements
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">Page {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Reports" path="/" />
        )}
      </div>
    </div>
  );
}

export default StreamsReportTable;
