import React from 'react';
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteStory from './DeleteStory';
import UpdateStory from './UpdateStory';

export const STORIESCOLUMNS = [
  {
    header: 'S/NO',
    accessor: 'id',
    Cell: props => <div> { (props.row.index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }</div>
  }, 
  {
    header: 'TITLE',
    accessor: 'title',
    Cell: props => <div className="capitalize">{ props.value}</div>
  },
  {
    header: 'Publisher',
    accessor: 'publisher.companyName',
    Cell: props => <div className="capitalize">{ props.value}</div>
  },
  {
    header: 'Date published',
    accessor: 'publication_date',
    Cell: props => <div className="capitalize">{ props.value ?? '01-Jan-2022' }</div>
  },
  {
    header: 'status',
    accessor: 'status',
    Cell: props => <div>{ checkStatus(props.value) }</div>
  },
  {
    header: 'action',
    accessor: 'action',
    Cell: props => <div>
      <Dropdown>
       
        <Dropdown.Toggle as="a" className="dropdown-ellipses icon-dropdown" id="dropdown-basic">
        <i className="fe fe-more-horizontal"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-end">
          <Link to={`/publications/stories/show/${props.row.original.id}`} className="dropdown-item">
            View
          </Link>

          {/* <Link to={`/publications/stories/edit/${props.row.original.id}`} className="dropdown-item">
            Edit
          </Link> */}

          {/* <div className={ (props.row.original.status) === "PUBLISHED" ? "dropdown-item disabled hidden" : "dropdown-item"}>
            <UpdateStory data={props.row.original}></UpdateStory>
          </div>

          <div className={ (props.row.original.status) !== "PUBLISHED" ? "dropdown-item disabled hidden" : "dropdown-item" }>
            <UpdateStory data={props.row.original}></UpdateStory>
          </div>


          <div className='dropdown-item text-danger'>
              <DeleteStory data={props.row.original}></DeleteStory>
          </div> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  }
 
]




function checkStatus(status) {
  switch (status) {
    case "PUBLISHED":
      return <div className="status">
              <div className="dot"></div>
                Published
            </div>  
  

    case "DRAFT":
      return <div className="status">
              <div className="dot inactive"></div>
                Draft
            </div> 
            
    case "UNPUBLISHED":
      return <div className="status">
              <div className="dot inactive"></div>
                Unpublished
            </div> 

  
    default:
      return <div className="status">
              <div className="dot inactive"></div>
                Inactive
            </div> 

  }

}




