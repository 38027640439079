import axios from "axios";
import api_config from "./api_config";

const baseURL = api_config.AUTH_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const loginApi = (data) => {
  return api({
    url: `/podcaster/login`,
    data: data,
    method: "POST",
  });
};

export const registerApi = (data, options) => {
  let headers = { contentType: "application/json" };
  if (options) {
    if (options.authorization) headers.authorization = options.authorization;
    if (options.contentType) headers.contentType = options.contentType;
  }
  return api({
    url: `/podcaster/register`,
    data: data,
    method: "POST",
    headers: headers,
  });
};

export const ForgotPasswordApi = (data) => {
  return api({
    url: `/podcaster/forgot`,
    data: data,
    method: "POST",
  });
};

export const verifyCodeApi = (data) => {
  return api({
    url: `/podcaster/verify-code`,
    data: data,
    method: "POST",
  });
};

export const resetPasswordApi = (data) => {
  return api({
    url: `/podcaster/password-reset`,
    data: data,
    method: "POST",
  });
};
export const updateAdminProfileApi = (data,token) => {
  return api({
    url: `/podcaster/${data.id}`,
    data: data,
    method: "PUT",
    headers:{Authorization:`Bearer ${token}`}
  });
};